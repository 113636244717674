import * as React from 'react';
import {
  ClickAwayListener,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { Check as CheckIcon, Copy as CopyIcon } from 'icons/figma';
import { copyToClipboard } from 'src/common/utils/copyToClipboard';

export type CopyToClipboardProps = IconButtonProps & {
  content: string;
  ariaLabel?: string;
  tooltipPlacement?: TooltipProps['placement'];
  IconProps?: React.SVGProps<SVGElement>;
};

export const CopyToClipboard = ({
  content,
  ariaLabel = 'Copy to clipboard',
  tooltipPlacement = 'top',
  IconProps = {},
  ...props
}: CopyToClipboardProps) => {
  const [copied, setCopied] = React.useState(false);
  const [error, setError] = React.useState(false);

  const getTitle = () => {
    if (copied) {
      return 'Copied!';
    }
    if (error) {
      return 'Failed to copy';
    }
    return ariaLabel;
  };

  const getVariant = () => {
    if (copied) {
      return 'success';
    }
    if (error) {
      return 'error';
    }
    return 'dark';
  };

  const resetStatus = () => {
    setCopied(false);
    setError(false);
  };

  return (
    <ClickAwayListener onClickAway={() => resetStatus()}>
      <Tooltip
        variant={getVariant()}
        title={getTitle()}
        placement={tooltipPlacement}
      >
        <IconButton
          size="small"
          variant="text"
          aria-label={ariaLabel}
          onClick={() => {
            copyToClipboard(content)
              .then(() => setCopied(true))
              .catch(() => {
                setError(true);
              });
          }}
          {...props}
        >
          {copied ? <CheckIcon {...IconProps} /> : <CopyIcon {...IconProps} />}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};
