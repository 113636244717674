/*
 * See src/ROUTES.md for documentation.
 */
import React from 'react';

type Redirect = {
  from: string;
  to: string;
  isRedirect: true;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
};

export type Route = {
  title: string;
  path: string;
  exact?: boolean;
  isTab?: boolean;
  isPrivate?: boolean;
  showLayout?: boolean;
  showFooter?: boolean;
  childComponent: React.LazyExoticComponent<React.ComponentType>;
};

type RouteOrRedirect = Route | Redirect;

export const isRedirect = (r: RouteOrRedirect): r is Redirect =>
  'isRedirect' in r && r.isRedirect;

export const isRoute = (r: RouteOrRedirect): r is Route =>
  ['title', 'path', 'childComponent'].every(key => key in r);

/* Authorization Routes */

export const LOGIN_ROUTE: Route = {
  title: 'Login',
  path: '/login',
  childComponent: React.lazy(() => import('src/Console/Auth/Login')),
};

export const LOGIN_VERIFY_ROUTE: Route = {
  title: 'Login',
  path: '/login/verify',
  childComponent: React.lazy(() => import('src/Console/Auth/LoginVerify')),
};

export const LOGOUT_ROUTE: Route = {
  title: 'Logout',
  path: '/logout',
  childComponent: React.lazy(() => import('src/Console/Auth/LogoutPage')),
};

export const AUTH_ROUTES: RouteOrRedirect[] = [
  LOGIN_VERIFY_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
];

export const isAuthRoute = () =>
  AUTH_ROUTES.some(r => isRoute(r) && window.location.pathname === r.path);

/* General Routes and entry-points for various entities (e.g., list pages) */

export const ACTIVITY_ROUTE: Route = {
  title: 'Activity',
  path: '/activity',
  isPrivate: true,
  childComponent: React.lazy(() => import('src/Console/Activity/ActivityPage')),
};

export const DASHBOARD_ROUTE: Route = {
  title: 'Dashboard',
  path: '/dashboard',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Dashboard/DashboardPage')
  ),
};

export const PROJECTS_LIST_ROUTE: Route = {
  title: 'Projects',
  path: '/projects',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/ProjectsListPage')
  ),
};

export const CONNECTIONS_ROUTE: Route = {
  title: 'Connections',
  path: '/connections',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Connections/ConnectionsPage')
  ),
};

export const LICENSE_ROUTE: Route = {
  title: 'Licenses',
  path: '/licenses',
  isPrivate: true,
  childComponent: React.lazy(() => import('src/Console/App/LicensePage')),
};

export const SETUP_ROUTE: Route = {
  title: 'Setup Account & Accept ToS',
  path: '/setup',
  showLayout: false,
  isPrivate: true,
  childComponent: React.lazy(() => import('src/Console/Setup/SetupPage')),
};

export const BLUEPRINTS_ROUTE: Route = {
  title: 'Blueprints',
  path: '/blueprints',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Blueprints/BlueprintsPage')
  ),
};

export const WORKFLOWS_ROUTE: Route = {
  title: 'Workflows',
  path: '/workflows',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/List/WorkflowsListPage')
  ),
};

/* Selected Project Routes */

// TODO: Commonalize & make `:project` param stricter (i.e. only allow [A-Za-z0-9-])

export const SELECTED_PROJECT_DATA_ARTIFACTS: Route = {
  title: 'Data Artifacts',
  path: '/:project/data_artifacts',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() => import('src/Console/Projects/Artifacts')),
};

export const getProjectRoute = (projectGuid: string) =>
  `/${projectGuid}/models`;

export const SELECTED_PROJECT_MODELS: Route = {
  title: 'Models',
  path: '/:project/models',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/ModelsListPage')
  ),
};

export const SELECTED_PROJECT_WORKFLOWS: Route = {
  title: 'Workflows',
  path: '/:project/workflows',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Workflows/ProjectWorkflowsListPage')
  ),
};

export const SELECTED_PROJECT_CONNECTIONS: Route = {
  title: 'Connections',
  path: '/:project/connections',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Projects/Connections/ProjectConnectionsListPage').then(
      ({ ProjectConnectionsListPage: Connections }) => ({
        default: Connections,
      })
    )
  ),
};

export const SELECTED_PROJECT_MODEL_DETAILS_DATA: Route = {
  title: 'Records & downloads',
  path: '/:project/models/:model/data',
  isPrivate: true,
  exact: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/Details/Data/ModelDataPage')
  ),
};

export const SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY: Route = {
  title: 'Activity',
  path: '/:project/models/:model/activity',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/Details/ModelActivityPage')
  ),
};

export const SELECTED_PROJECT_MODEL_DETAILS_CONFIG: Route = {
  title: 'Config',
  path: '/:project/models/:model/config',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/Details/ModelConfigPage')
  ),
};

export const SELECTED_PROJECT_MODEL_DETAILS_REPORT: Route = {
  title: 'Report',
  path: '/:project/models/:model/report',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/Details/Report/ModelReportPage')
  ),
};

export const SELECTED_PROJECT_MODEL_RECORD_HANDLER_DETAILS: Route = {
  title: 'Records',
  path: '/:project/models/:model/recordHandlers/:recordHandler',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Models/Details/RecordHandlerPage')
  ),
};

export const SELECTED_PROJECT_SETTINGS: Route = {
  title: 'Settings',
  path: '/:project/settings',
  isPrivate: true,
  isTab: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Settings/ProjectSettingsPage')
  ),
};

export const SELECTED_PROJECT_MEMBER_SETTINGS: Route = {
  title: 'Members',
  path: '/:project/members',
  isPrivate: true,
  isTab: true,
  childComponent: React.lazy(
    () => import('src/Console/Projects/Members/ProjectMembersPage')
  ),
};

// export wrapper to set the landing page for model details
export const SELECTED_PROJECT_MODEL_DETAILS =
  SELECTED_PROJECT_MODEL_DETAILS_DATA;

export const SELECTED_PROJECT = SELECTED_PROJECT_WORKFLOWS;

export const SELECTED_PROJECT_MODEL_DETAILS_ROUTES = [
  SELECTED_PROJECT_MODEL_DETAILS_DATA,
  SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY,
  SELECTED_PROJECT_MODEL_DETAILS_CONFIG,
  SELECTED_PROJECT_MODEL_DETAILS_REPORT,
  // Don't include the record handler results because we don't want it to have the same layout.
  // SELECTED_PROJECT_MODEL_RECORD_HANDLER_DETAILS,
];

/**
 * Note: this is NOT all the pages that require a selected project to work, rather
 * all the pages that use the Project Layout from App/Layout/Root
 */
export const SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT: RouteOrRedirect[] = [
  SELECTED_PROJECT_WORKFLOWS,
  SELECTED_PROJECT_MODELS,
  SELECTED_PROJECT_CONNECTIONS,
  SELECTED_PROJECT_DATA_ARTIFACTS,
  SELECTED_PROJECT_MEMBER_SETTINGS,
  SELECTED_PROJECT_SETTINGS,
  ...SELECTED_PROJECT_MODEL_DETAILS_ROUTES,
  SELECTED_PROJECT_MODEL_RECORD_HANDLER_DETAILS,
  {
    from: '/:project/',
    to: SELECTED_PROJECT.path,
    exact: true,
    isRedirect: true,
  },
];

export const SELECTED_PROJECT_REQUIRED_ROUTES: RouteOrRedirect[] =
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT;

// builder requires a project url param, but does not get the layout of other project pages
export const BUILDER: Route = {
  title: 'Builder',
  path: '/:project/builder',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Builder').then(({ Builder }) => ({ default: Builder }))
  ),
};

export const BUILDER_LANDING_PAGE: Route = {
  title: 'Builder',
  path: '/builder',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Builder/BuilderLandingPage').then(
      ({ BuilderLandingPage }) => ({
        default: BuilderLandingPage,
      })
    )
  ),
};

export const WORKFLOW_DETAILS_ROUTE: Route = {
  title: 'Workflow',
  path: '/workflows/:workflow_id',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/Detail/WorkflowDetailsPage')
  ),
};

export const WORKFLOW_CONFIG_ROUTE: Route = {
  title: 'Workflow Configuration',
  path: '/workflows/:workflow_id/configuration',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/Detail/Config/WorkflowConfigPage')
  ),
};

export const WORKFLOW_RUN_ACTIVITY_ROUTE: Route = {
  title: 'Workflow Runs',
  path: '/workflows/:workflow_id/runs/:workflow_run_id',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Workflows/Runs/WorkflowActivityPage').then(
      ({ WorkflowActivityPage }) => ({
        default: WorkflowActivityPage,
      })
    )
  ),
};

export const WORKFLOW_RUN_MODELS_ROUTE: Route = {
  title: 'Workflow Run Models',
  path: '/workflows/:workflow_id/runs/:workflow_run_id/models',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/Runs/Models/WorkflowRunModelsListPage')
  ),
};

export const WORKFLOW_RUN_CONFIG_ROUTE: Route = {
  title: 'Workflow Configuration',
  path: '/workflows/:workflow_id/runs/:workflow_run_id/configuration',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/Runs/WorkflowRunConfigPage')
  ),
};

export const USE_CASES_PROJECTS_ROUTE: Route = {
  title: 'Choose Project',
  path: '/use_cases/cards/:card_id/projects',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/UseCases/ChooseProject/ChooseProjectPage')
  ),
};

export const USE_CASES_MODEL_TYPE_ROUTE: Route = {
  title: 'Choose Model',
  path: '/use_cases/cards/:card_id/model_types',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/UseCases/ChooseModelType/ChooseModelTypePage')
  ),
};

export const USE_CASES_DATASOURCES_ROUTE: Route = {
  title: 'Data artifact',
  path: '/use_cases/cards/:card_id/data_artifacts',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/UseCases/DataSource/ChooseDataSourcePage')
  ),
};

export const BLUEPRINT_MODEL_CONFIGURE: Route = {
  title: 'Create Model',
  path: '/use_cases/cards/:card_id/model/new',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/UseCases/Configure/ConfigureModelPage')
  ),
};

export const WORKFLOW_RUN_ROUTE: Route = {
  title: 'Workflow run',
  path: '/use_cases/cards/:card_id/workflow/run',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Workflows/Run/ConfigureWorkflowPage')
  ),
};

export const WORKFLOW_SCHEDULE_ROUTE: Route = {
  title: 'Workflow Schedule',
  path: '/use_cases/cards/:card_id/workflow/schedule',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/UseCases/Schedule/ChooseSchedulePage')
  ),
};

export const USE_CASES_CARD_DETAILS_ROUTE: Route = {
  title: 'Blueprint Details',
  path: '/use_cases/cards/:card_id',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Blueprints/Detail/BlueprintDetailPage')
  ),
};

export const PLAYGROUND_ROUTE: Route = {
  title: 'Navigator',
  path: '/navigator',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Playground/NavigatorPlayground/PlaygroundPage')
  ),
};

export const PLAYGROUND_GENERATE_ROUTE: Route = {
  title: 'Navigator - Generate Model',
  path: '/navigator/generate',
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Playground/Generate/GeneratePage')
  ),
};

export const CHAT_ROUTE: Route = {
  title: 'Chat',
  path: '/chat',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Chat/ChatPage').then(({ ChatPage }) => ({
      default: ChatPage,
    }))
  ),
};

export const CHAT_SESSION_ROUTE: Route = {
  title: 'Chat thread',
  path: '/chat/:chat_id',
  isPrivate: true,
  showFooter: false,
  childComponent: React.lazy(() =>
    import('src/Console/Chat/ChatSession').then(({ ChatSession }) => ({
      default: ChatSession,
    }))
  ),
};

export const GENERAL_ROUTES: RouteOrRedirect[] = [
  ACTIVITY_ROUTE,
  DASHBOARD_ROUTE,
  /* Do not add to SELECTED_PROJECT_ROUTES to prevent project layout from showing */
  BLUEPRINT_MODEL_CONFIGURE,
  /* --END-- */
  PROJECTS_LIST_ROUTE,
  CONNECTIONS_ROUTE,
  LICENSE_ROUTE,
  SETUP_ROUTE,
  BUILDER,
  BUILDER_LANDING_PAGE,
  WORKFLOW_RUN_ROUTE,
  WORKFLOWS_ROUTE,
  WORKFLOW_SCHEDULE_ROUTE,
  WORKFLOW_DETAILS_ROUTE,
  WORKFLOW_CONFIG_ROUTE,
  WORKFLOW_RUN_ACTIVITY_ROUTE,
  WORKFLOW_RUN_MODELS_ROUTE,
  WORKFLOW_RUN_CONFIG_ROUTE,
  WORKFLOW_CONFIG_ROUTE,
  BLUEPRINTS_ROUTE,
  USE_CASES_DATASOURCES_ROUTE,
  USE_CASES_PROJECTS_ROUTE,
  USE_CASES_MODEL_TYPE_ROUTE,
  USE_CASES_CARD_DETAILS_ROUTE,
  {
    from: '/playground/generate',
    to: '/navigator/generate',
    isRedirect: true,
  },
  PLAYGROUND_GENERATE_ROUTE,
  {
    from: '/playground',
    to: '/navigator',
    isRedirect: true,
  },
  PLAYGROUND_ROUTE,
  CHAT_ROUTE,
  CHAT_SESSION_ROUTE,
  {
    from: '/projects/:project',
    to: SELECTED_PROJECT.path,
    isRedirect: true,
  },
];

/* User Routes */

export const USER_API_KEY_ROUTE: Route = {
  title: 'API Key',
  path: '/users/me/key',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Users/Me/UserAPIKeyPage')
  ),
};

export const USER_INVITES_ROUTE: Route = {
  title: 'Invites',
  path: '/users/me/invites',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Users/Me/Invites/InvitesPage')
  ),
};

export const USER_BILLING_ROUTE: Route = {
  title: 'Billing',
  path: '/users/me/billing',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Me/Billing/UserBillingPage').then(
      ({ UserBillingPage }) => ({
        default: UserBillingPage,
      })
    )
  ),
};

export const USER_BILLING_CHOOSE_PLAN_ROUTE: Route = {
  title: 'Choose Plan',
  path: '/users/me/billing/plans',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Users/Me/Billing/ChooseABillingPlanPage')
  ),
};

export const USER_BILLING_REVIEW_PLAN_ROUTE: Route = {
  title: 'Review Plan',
  path: '/users/me/billing/review_plan',
  exact: true,
  isPrivate: true,
  childComponent: React.lazy(
    () =>
      import('src/Console/Users/Me/Billing/Review/ReviewBillingPlanChangesPage')
  ),
};

export const USER_USAGE_ROUTE: Route = {
  title: 'Usage',
  path: '/users/me/usage',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Me/Usage/UserUsagePage').then(
      ({ UserUsagePage }) => ({
        default: UserUsagePage,
      })
    )
  ),
};

export const USER_ROUTE: Route = {
  title: 'Profile',
  path: '/users/me',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(
    () => import('src/Console/Users/Me/Profile/ProfilePage')
  ),
};

export const ACCOUNT_SETTINGS_ROUTES: Route[] = [
  USER_ROUTE,
  USER_API_KEY_ROUTE,
  USER_INVITES_ROUTE,
];

// Organization Routes
export const ORGANIZATION_ROUTE: Route = {
  title: 'Members',
  path: '/organization/members',
  exact: true,
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Organization/Members/OrgMembersPage').then(
      ({ OrgMembersPage }) => ({
        default: OrgMembersPage,
      })
    )
  ),
};
export const ORG_BILLING_ROUTE: Route = {
  title: 'Billing',
  path: '/organization/billing',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Organization/Billing/OrgBillingPage').then(
      ({ OrgBillingPage }) => ({
        default: OrgBillingPage,
      })
    )
  ),
};

export const ORG_USAGE_ROUTE: Route = {
  title: 'Usage',
  path: '/organization/usage',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Organization/Usage/OrgUsagePage').then(
      ({ OrgUsagePage }) => ({
        default: OrgUsagePage,
      })
    )
  ),
};

export const ORG_SETTINGS_ROUTE: Route = {
  title: 'Settings',
  path: '/organization/settings',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Organization/Settings/OrgSettingsPage').then(
      ({ OrgSettingsPage }) => ({
        default: OrgSettingsPage,
      })
    )
  ),
};

export const ORG_PROJECTS_ROUTE: Route = {
  title: 'Projects',
  path: '/organization/projects',
  isTab: true,
  isPrivate: true,
  childComponent: React.lazy(() =>
    import('src/Console/Users/Organization/Projects/OrgProjectsPage').then(
      ({ OrgProjectsPage }) => ({
        default: OrgProjectsPage,
      })
    )
  ),
};

export const ORGANIZATION_SETTINGS: Route[] = [
  ORGANIZATION_ROUTE,
  ORG_BILLING_ROUTE,
  ORG_USAGE_ROUTE,
  ORG_SETTINGS_ROUTE,
  ORG_PROJECTS_ROUTE,
];

export const USER_ROUTES: Route[] = [
  USER_ROUTE,
  USER_API_KEY_ROUTE,
  USER_INVITES_ROUTE,
  USER_BILLING_ROUTE,
  USER_USAGE_ROUTE,
  USER_BILLING_CHOOSE_PLAN_ROUTE,
  USER_BILLING_REVIEW_PLAN_ROUTE,
];

export const TEAM_USER_ROUTES: Route[] = [
  USER_ROUTE,
  USER_API_KEY_ROUTE,
  USER_INVITES_ROUTE,
];

/**
 * A list of routes that allow the model completion dialog feature.
 * We don't allow it on all pages so as not to disrupt important user workflows,
 * such as setting up a new blueprint model or workflow, managing billing, or
 * managing their account/team.
 */
export const MODEL_COMPLETION_DIALOG_ALLOWED_ROUTES = [
  ACTIVITY_ROUTE,
  BLUEPRINTS_ROUTE,
  CONNECTIONS_ROUTE,
  DASHBOARD_ROUTE,
  PROJECTS_LIST_ROUTE,
  SELECTED_PROJECT_MODELS,
  SELECTED_PROJECT_DATA_ARTIFACTS,
  SELECTED_PROJECT_MODEL_DETAILS_ROUTES,
];

/* All Routes */

export const ALL_ROUTES: RouteOrRedirect[] = [
  ...AUTH_ROUTES,
  ...GENERAL_ROUTES,
  ...USER_ROUTES,
  ...ORGANIZATION_SETTINGS,
  ...SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
  { from: '([^.]*)', to: DASHBOARD_ROUTE.path, isRedirect: true },
];

export default ALL_ROUTES;

/** Route Helper Functions */
export const getUseCaseDataSourcePath = (cardId: string) =>
  `/use_cases/cards/${cardId}/data_artifacts`;
export const getUseCaseModelTypePath = (cardId: string) =>
  `/use_cases/cards/${cardId}/model_types`;
export const getUseCaseProjectPath = (cardId: string) =>
  `/use_cases/cards/${cardId}/projects`;
export const getBlueprintModelConfigPath = (cardId: string) =>
  `/use_cases/cards/${cardId}/model/new`;
export const getUseCaseWorkflowPath = (cardId: string) =>
  `/use_cases/cards/${cardId}/workflow/run`;
export const getWorkflowSchedulePath = (cardId: string) =>
  `/use_cases/cards/${cardId}/workflow/schedule`;
export const getWorkflowRunPath = (cardId: string) =>
  `/use_cases/cards/${cardId}/workflow/run`;
export const getWorkflowDetailPath = (workflowId: string) =>
  `/workflows/${workflowId}`;
export const getWorkflowRunActivityPath = (
  workflowId: string,
  workflowRunId: string
) => `/workflows/${workflowId}/runs/${workflowRunId}`;
export const getSelectedProjectModelActivityPage = (
  projectGuid: string,
  modelId: string
) => `/${projectGuid}/models/${modelId}/activity`;

/**
 * NOTHING BELOW SHOULD BE INCLUDED IN {@link ALL_ROUTES}
 * Don't add external URL constants here -- use src/common/constants/urls.ts instead
 */

/* Login */
export const GITHUB_OAUTH_ROUTE =
  `${process.env.BACKEND_URL}/auth/github` as const;
export const GOOGLE_OAUTH_ROUTE =
  `${process.env.BACKEND_URL}/auth/google` as const;

export const GRETEL_BLUEPRINTS_URL =
  process.env.BLUEPRINTS_URL || 'https://blueprints.gretel.cloud';

export const getSampleDatasetUrl = (fileName: string) =>
  `${GRETEL_BLUEPRINTS_URL}/sample_data/${fileName}`;
export const blueprintCardThumbnail = (imageName: string) =>
  `${GRETEL_BLUEPRINTS_URL}/use_cases/icons/${imageName}`;
export const getUseCaseDetailsPath = (detailsFileName: string) =>
  `${GRETEL_BLUEPRINTS_URL}/use_cases/details/${detailsFileName}`;
export const GRETEL_SYNTHETICS_CONFIG_TEMPLATES = `${GRETEL_BLUEPRINTS_URL}/config_templates/gretel/synthetics`;

/* Use Cases */
export const USE_CASE_CONFIG = `${GRETEL_BLUEPRINTS_URL}/use_cases/gretel.json`;
export const MODEL_TYPES_CONFIG = `${GRETEL_BLUEPRINTS_URL}/model_types/modelTypesList.json`;
export const PLAYGROUND_EXAMPLE_PROMPT_URL = `${GRETEL_BLUEPRINTS_URL}/prompts/all.json`;

// NOTE: Don't add external URL constants here -- use src/common/constants/urls.ts instead
