import { isFulfilled } from '@reduxjs/toolkit';
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { initializeSelected } from 'src/api/tenants';
import { makeBaseQuery } from './baseQuery';

const CLOUD_ENDPOINTS = /^\/?auth/;

export const tenantBaseQuery = (options?: FetchBaseQueryArgs) => {
  const rawBaseQuery = makeBaseQuery(options);
  const baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const url = new URL(
      typeof args === 'string' ? args : args.url,
      process.env.BACKEND_URL
    );

    // Only adjust the URL for some requests, services like auth
    // should continue going to our cloud API. -md
    if (!CLOUD_ENDPOINTS.test(url.pathname)) {
      const selected = await api.dispatch(initializeSelected());
      if (isFulfilled(selected) && selected.payload?.config.api_endpoint) {
        // This user has a tenant selected, adjust the URL to use the tenant's API endpoint. -md
        url.hostname = selected.payload.config.api_endpoint;
      }
    }

    const adjustedArgs =
      typeof args === 'string'
        ? url.toString()
        : { ...args, url: url.toString() };

    return rawBaseQuery(adjustedArgs, api, extraOptions);
  };
  return baseQuery;
};
