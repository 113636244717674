import * as React from 'react';
import { Alert, Box } from '@mui/material';
import { RenderProps } from 'prism-react-renderer';
import { LineNumbers } from './LineNumbers';

type Props = {
  index: number;
  line: {
    types: string[];
    content: string;
    empty?: boolean;
  }[];
  numberOfLines: number;
  getLineProps: RenderProps['getLineProps'];
  getTokenProps: RenderProps['getTokenProps'];
  dark?: boolean;
  options?: {
    highlight?: { severity: string; message: string };
    showLineNumber?: boolean;
    numberOffset: number;
  };
  className?: string;
};

export const Line = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      index,
      line,
      dark,
      numberOfLines,
      options = {
        showLineNumber: true,
        numberOffset: 1,
      },
      getLineProps,
      getTokenProps,
      className,
      ...props
    },
    ref
  ) => (
    <>
      <Box
        {...getLineProps({ line, key: index })}
        sx={{
          display: 'flex',
          // First Line
          ...(index === 0 && {
            mt: 2,
          }),
          // Last Line
          ...(index === numberOfLines - 1 && {
            mb: 2,
          }),
          // Highlight
          ...(options.highlight && {
            backgroundColor: 'warning.800',
          }),
        }}
        className={className}
        ref={ref}
        {...props}
      >
        {options.showLineNumber && (
          <LineNumbers
            index={index}
            offset={options.numberOffset}
            numberOfLines={numberOfLines}
            highlight={options.highlight !== undefined}
            dark={dark}
          />
        )}
        <Box component="span" sx={{ ml: 4, display: 'inline-block' }}>
          {line.map((token, key) => (
            <span key={key} {...getTokenProps({ token, key })} />
          ))}
        </Box>
      </Box>
      {options.highlight?.message && (
        <Alert
          shadow={false}
          severity="warning"
          sx={theme => ({
            backgroundColor: 'transparent',
            color: 'warning.contrastText',
            display: 'flex',
            p: 2,
            border: 1,
            borderColor: 'warning.main',
            borderStyle: 'dashed',
            borderLeft: theme.spacing(2),
            borderLeftStyle: 'solid',
            borderLeftColor: 'warning.main',
            borderRadius: 0,
          })}
        >
          {options.highlight.message}
        </Alert>
      )}
    </>
  )
);

Line.displayName = 'Line';
