import * as React from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  index: number;
  offset: number;
  numberOfLines: number;
  highlight?: boolean;
  className?: string;
  dark?: boolean;
};

export const LineNumbers = React.forwardRef<HTMLSpanElement, Props>(
  (props, ref) => {
    const {
      index,
      offset,
      numberOfLines,
      highlight,
      className,
      dark,
      ...spanProps
    } = props;

    // Need filler &nbsp; if we have more than single digits.
    const filler = new Array(
      numberOfLines.toString().length - (index + offset).toString().length
    ).fill('\xa0');

    return (
      <Box
        component="span"
        sx={{
          backgroundColor: dark ? 'primary.950' : 'border.default',
          color: dark ? 'primary.100.' : 'grey.500',
          display: 'inline-block',
          userSelect: 'none',
          // Fist Line
          ...(index === 0 && {
            pt: 2,
            mt: -2,
          }),
          // Last Line
          ...(index === numberOfLines - 1 && {
            pb: 2,
            mb: -2,
          }),
        }}
        ref={ref}
        {...spanProps}
      >
        <Typography
          variant="code"
          component="span"
          sx={{
            display: 'inline-block',
            px: 1.5,
            py: 0,
            backgroundColor: highlight ? 'warning.800' : 'initial',
          }}
        >
          {filler && filler.map(char => char)}
          {index + offset}
        </Typography>
      </Box>
    );
  }
);

LineNumbers.displayName = 'LineNumbers';
