import * as React from 'react';

type SidebarState = 'collapsed' | 'expanded';

export const useSidebarStatus = () => {
  const setSidebarState = (newValue: SidebarState) => {
    window.localStorage.setItem('sidebar', newValue);
    window.dispatchEvent(
      new StorageEvent('storage', { key: 'sidebar', newValue })
    );
  };

  const openSidebar = () => setSidebarState('expanded');
  const closeSidebar = () => setSidebarState('collapsed');

  const getSnapshot = () => localStorage.getItem('sidebar') as SidebarState;

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener);
    return () => void window.removeEventListener('storage', listener);
  };

  const store = React.useSyncExternalStore(subscribe, getSnapshot);

  return {
    isSidebarOpen: store !== 'collapsed',
    openSidebar,
    closeSidebar,
  };
};
