import * as React from 'react';
import { Box, styled } from '@mui/material';
import { Highlight, Language } from 'prism-react-renderer';
import { CopyToClipboard } from 'design-system';
import {
  useThemeForDarkPrism,
  useThemeForLightPrism,
} from 'src/theme/prismTheme';
import { Line } from './components/Line';

/**
 * This is theoretically the lineHeight of the <pre> tag,
 * but it's really just a **magic** number.
 * Find a way to infer it from the actual `lineHeight` in CSS? */
const ROW_HEIGHT = 24;

export type CodeBlockProps = RequireAtLeastOne<
  {
    code?: string;
    children?: string;
    language: Language;
    copy?: boolean;
    numbers?: boolean;
    numberOffset?: number;
    highlight?: Record<number, { severity: string; message: string }>;
    className?: string;
    dark?: boolean;
    maxRows?: number;
  },
  'code' | 'children'
>;

type StyledPreProps = { maxRows?: number };
const StyledPre = styled('pre', {
  shouldForwardProp: prop => prop !== 'maxRows',
})<StyledPreProps>(({ theme, maxRows }) => ({
  borderRadius: theme.shape.radii.large,
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  maxHeight: maxRows ? maxRows * ROW_HEIGHT : 'inherit',
  height: 'inherit',
}));

export const CodeBlock = (props: CodeBlockProps) => {
  const {
    code,
    children,
    language,
    copy = true,
    numbers = true,
    numberOffset = 1,
    highlight,
    dark,
    maxRows,
    ...restOfProps
  } = props;
  const darkTheme = useThemeForDarkPrism();
  const lightTheme = useThemeForLightPrism();

  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover #copyToClipboard': {
          display: 'inline-flex',
        },
      }}
      {...restOfProps}
    >
      <Highlight
        theme={dark ? darkTheme : lightTheme}
        code={children || code || ''}
        language={language}
      >
        {({
          className: prismClassName,
          style,
          tokens,
          getLineProps,
          getTokenProps,
        }) => (
          <>
            {copy && (
              <CopyToClipboard
                id="copyToClipboard"
                sx={{
                  animation: 'fade-in .2s both',
                  display: 'none',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  m: 1,
                  ...(dark
                    ? {
                        color: 'primary.contrastText',
                        backgroundColor: 'primary.dark',
                        '&:hover, &:active, &:focus': {
                          color: 'primary.contrastText',
                          backgroundColor: 'primary.dark',
                        },
                      }
                    : {
                        backgroundColor: 'background.inset',
                      }),
                }}
                content={(code || children) ?? ''}
                IconProps={{ height: 16, width: 16 }}
              />
            )}
            <StyledPre
              className={prismClassName}
              style={style}
              maxRows={maxRows}
            >
              {tokens.map((line, i) => (
                <Line
                  key={i}
                  index={i}
                  line={line}
                  dark={dark}
                  numberOfLines={tokens.length}
                  options={{
                    showLineNumber: numbers,
                    numberOffset,
                    highlight: highlight?.[i + numberOffset],
                  }}
                  getLineProps={getLineProps}
                  getTokenProps={getTokenProps}
                />
              ))}
            </StyledPre>
          </>
        )}
      </Highlight>
    </Box>
  );
};
