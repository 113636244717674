/* eslint react-hooks/exhaustive-deps: 'off' */
/** @todo turn back on and fix exhaustive-deps for useCallback/useMemo/useEffect @see INT-483 */
import * as React from 'react';
import { styled, Toolbar } from '@mui/material';
import { DRAWER_WIDTH } from 'common/constants/layouts';
import Footer from 'common/Footer';
import { useSidebarStatus } from 'common/useSidebarStatus';
import { ModelCompletionDialogManager } from 'src/Console/Projects/Models/CompletionDialog';
import {
  MODEL_COMPLETION_DIALOG_ALLOWED_ROUTES,
  Route,
  SELECTED_PROJECT_MODEL_DETAILS_ROUTES,
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';
import useSelectedProject from 'utils/useSelectedProject';
import AppSidebar from '../../AppSidebar';
import AppToolbar from '../../AppToolbar';
import ModelDetailsLayout from '../Model';
import ProjectLayout from '../Project';
import ProjectNotFound from '../Project/NotFound';

const StyledRoot = styled('div')(() => ({
  height: '100vh',
  minWidth: '1024px',
}));

const StyledOffsetDiv = styled('div', {
  shouldForwardProp: prop => prop !== 'isSidebarOpen',
})<{ isSidebarOpen: boolean }>(({ theme, isSidebarOpen }) => ({
  transition: isSidebarOpen
    ? theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  marginLeft: isSidebarOpen ? DRAWER_WIDTH : theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

interface RootLayoutProps {
  showFooter?: boolean;
  children?: React.ReactNode;
}

const RootLayout = ({ showFooter = true, children }: RootLayoutProps) => {
  const { isSidebarOpen } = useSidebarStatus();

  const {
    consoleRoute,
    location: { pathname },
  } = useConsoleRoute({ withRouterContext: true });

  React.useEffect(() => {
    const wrapper = document.querySelector('#offset-scroll-container');
    if (wrapper) {
      wrapper.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <>
      <StyledRoot>
        <AppToolbar />
        <AppSidebar />
        <StyledOffsetDiv
          isSidebarOpen={isSidebarOpen}
          id="offset-scroll-container"
        >
          <Toolbar
            variant="dense"
            sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
          />
          <main>
            {SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT.includes(
              consoleRoute
            ) ? (
              <ProjectLayoutWrapper consoleRoute={consoleRoute}>
                {children}
              </ProjectLayoutWrapper>
            ) : (
              children
            )}
          </main>
          {showFooter && <Footer />}
        </StyledOffsetDiv>
      </StyledRoot>
      {MODEL_COMPLETION_DIALOG_ALLOWED_ROUTES.includes(consoleRoute) && (
        <ModelCompletionDialogManager />
      )}
    </>
  );
};

const ProjectLayoutWrapper = ({
  children,
  consoleRoute,
}: React.PropsWithChildren<{ consoleRoute: Route }>) => {
  const { isError: isProjectQueryError } = useSelectedProject();

  return isProjectQueryError ? (
    <ProjectNotFound />
  ) : (
    <ProjectLayout>
      {SELECTED_PROJECT_MODEL_DETAILS_ROUTES.includes(consoleRoute) ? (
        <ModelDetailsLayout>{children}</ModelDetailsLayout>
      ) : (
        children
      )}
    </ProjectLayout>
  );
};

export default RootLayout;
