import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { isReady } from 'common/utils/stage';
import { CopyToClipboard } from 'design-system';
import { initializeSelected, setSelected } from 'src/api/tenants';
import { resetAllApiState } from 'src/api/utils/reset';
import { useAppDispatch, useAppSelector } from 'src/store.types';

export const TenantSelect = () => {
  const dispatch = useAppDispatch();

  const { tenants, selected, stage } = useAppSelector(store => store.tenants);

  const handleChange = React.useCallback(
    (next: string) => {
      const tenant = tenants.find(({ guid }) => guid === next);
      if (tenant) {
        dispatch(setSelected(tenant));

        // Reset all API states to ensure we're using the correct API endpoint.
        dispatch(resetAllApiState());
      }
    },
    [dispatch, tenants]
  );

  React.useEffect(() => {
    if (isReady(stage)) {
      dispatch(initializeSelected());
    }
  }, [stage, dispatch]);

  if (!selected || !tenants.length) {
    return null;
  }

  return (
    <>
      <Select
        value={selected.guid}
        onChange={e => handleChange(e.target.value)}
        sx={{ mr: 2 }}
      >
        {tenants.map(({ guid, name, cloud_provider }) => (
          <MenuItem key={guid} value={guid}>
            {name} ({cloud_provider.provider} {cloud_provider.region})
          </MenuItem>
        ))}
      </Select>
      {selected?.config?.api_endpoint && (
        <CopyToClipboard
          ariaLabel="Copy API Endpoint"
          size="large"
          content={`https://${selected.config.api_endpoint}`}
        />
      )}
    </>
  );
};
