import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material';
import { CodeBlock } from 'design-system';
import { CodeFile } from 'icons/figma';

interface ChatCodeBlockProps {
  language: string;
  code: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.radii.medium,
  border: '1px solid',
  borderColor: theme.palette.border.default,
  backgroundColor: theme.palette.background.secondary,
}));

export const ChatCodeBlock = ({ language, code }: ChatCodeBlockProps) => {
  return (
    <StyledBox>
      <Accordion defaultExpanded={false} sx={{ background: 'transparent' }}>
        <AccordionSummary>
          <SvgIcon sx={{ width: 20, height: 20, mr: 2, opacity: 0.75 }}>
            <CodeFile />
          </SvgIcon>
          <Typography variant="body2Medium">Code Snippet</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CodeBlock language={language} code={code} />
        </AccordionDetails>
      </Accordion>
    </StyledBox>
  );
};
