import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { clearToken, getAuthorization } from 'common/utils/token';
import { isAuthRoute } from 'src/routes';
import { redirectToLogin } from 'utils/login';

/**
 * Checks if the current instance of console is using a `gretel.ai` domain.
 *
 * This will only be true on eng, staging, and production, false on local and preview.
 * @returns if the current host is on `gretel.ai`
 */
export const isGretelHost = () =>
  window.location.hostname.endsWith('.gretel.ai');

export const makeBaseQuery = (options?: FetchBaseQueryArgs) =>
  fetchBaseQuery({
    async prepareHeaders(headers) {
      headers.set('authorization', getAuthorization() || 'use-credentials');
      return headers;
    },
    validateStatus({ status }) {
      // Redirect to /login if a request fails due to an auth error
      // and we aren't already on the login page. -md
      if (status === 401 && !isAuthRoute()) {
        clearToken();
        redirectToLogin({
          redirect: window.location.href,
        });
      }
      return status >= 200 && status < 400;
    },
    // Only provide credentials when we can use a cookie. -md
    credentials: isGretelHost() ? 'include' : 'omit',
    ...options,
  });
