import Papa from 'papaparse';
import { parse, parseDocument } from 'yaml';
import {
  CSV_EXTENSION_REGEX,
  DataError,
  FILE_TYPES,
  FILENAME_REGEX,
  INVALID_FILE_TYPE_ERROR,
  INVALID_FILENAME_ERROR,
  JSON_EXTENSION_REGEX,
  JSONL_EXTENSION_REGEX,
  PARQUET_EXTENSION_REGEX,
} from 'constants/data';
import { ProjectArtifact } from 'src/api/types/Artifact';
import { Status } from 'src/api/types/ModelRuns';
import { Project } from 'src/api/types/Project';
import { shorthandDistance } from 'utils/date';
import Formatters from 'utils/formatters';
import { DateStringISO } from 'utils/formatters/types';
import { logger } from './logger';

export type FileUpload = File & {
  artifactKey?: string;
  columnCount: number;
  rowCount: number;
  projectId?: string;
  newProject?: boolean;
  forModel?: boolean;
  projectName?: string;
  parsed?: unknown;
  fileType: string;
  path?: string;
};

export const getDataWithOther = <DoesNotMatter>(
  data: Array<DoesNotMatter>,
  label: string,
  value: string,
  styles = {},
  limit = 2
) => {
  const theSomebodies = data.slice(0, limit);
  const theNobodies = data.slice(limit);
  return [
    ...theSomebodies,
    ...(theNobodies.length
      ? [
          theNobodies.reduce(
            (other, dataPoint) => ({
              ...other,
              [value]: (other[value] += dataPoint[value] || 0),
            }),
            {
              ...styles,
              [label]: `Other (${theNobodies.length})`,
              [value]: 0,
            }
          ),
        ]
      : []),
  ];
};

type ParsedCSV = {
  fileKey?: string;
  result: unknown[];
  columnCount: number;
  rowCount: number;
  fileType: FILE_TYPES.CSV;
};
/**
 * Wrapper to present papaparse as a Promise with our needs in mind.
 */
export const parseCSV = ({
  file,
  fileKey,
  ...papaProps
}: Papa.ParseConfig & {
  file: File;
  fileKey?: string;
}): Promise<ParsedCSV> =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      worker: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      ...papaProps,
      complete: ({ data, meta, errors }) =>
        errors.length
          ? reject(errors)
          : resolve({
              fileKey,
              result: data,
              columnCount: meta.fields?.length ?? 0,
              rowCount: data?.length,
              fileType: FILE_TYPES.CSV,
            }),
    });
  });

/**
 * Promise wrapper for async file operations.
 * Determines a file's type (with {@link getFileType}), then uses the appropriate parser to convert the file's data into
 * ES objects, as well as pulling out stats relevant to our needs.
 * @param {File} file The file to parse
 * @param {String} [fileKey] The fileKey, if any
 * @returns {Promise<Object>} A promise that will resolve/reject when the file has been parsed.
 */

export type FileStats = {
  fileType: FILE_TYPES;
  result: unknown;
  columnCount: number;
  rowCount: number;
  fileKey?: string;
};

export const getFileStats = ({
  file,
  fileKey,
}: {
  file: File;
  fileKey?: string;
}): Promise<FileStats> => {
  return new Promise((resolve, reject) => {
    try {
      const fileType = getFileType(file.name);

      if ([FILE_TYPES.JSON, FILE_TYPES.JSONL].includes(fileType)) {
        const reader = new FileReader();
        reader.onerror = err => {
          reject(err);
        };
        reader.onloadend = () => {
          if (reader.result === null) {
            return reject('failed to load');
          }

          let resultString: string;
          if (reader.result instanceof ArrayBuffer) {
            const dec = new TextDecoder();
            resultString = dec.decode(reader.result);
          } else {
            resultString = reader.result;
          }

          try {
            let result = parseJSON(resultString);
            if (!Array.isArray(result)) {
              if (fileType !== FILE_TYPES.JSONL) {
                return reject('Invalid file contents');
              } else {
                result = [result];
              }
            }
            const { columnCount } = result.reduce(
              (acc, row) => {
                acc.columnCount += Object.keys(row).filter(
                  key => !acc.seen.includes(key)
                ).length;
                acc.seen.push(
                  Object.keys(row).filter(key => !acc.seen.includes(key))
                );
                return acc;
              },
              { columnCount: 0, seen: [] }
            );
            resolve({
              fileKey,
              result,
              columnCount,
              rowCount: result.length,
              fileType,
            });
          } catch (e) {
            reject(e);
          }
        };
        reader.readAsText(file);
      } else if (fileType === FILE_TYPES.CSV) {
        parseCSV({ file, fileKey })
          .then(fileDeets => resolve(fileDeets))
          .catch(errors => {
            if (errors[0].code === 'UndetectableDelimiter') {
              // Try again with specified delimiter in case it's a single column or Chrome is acting like Safari
              parseCSV({ file, fileKey, delimiter: ',' })
                .then(fileDeets => resolve(fileDeets))
                .catch(errors => reject(errors[0].message));
            } else {
              reject(errors[0].message);
            }
          });
      } else {
        resolve({
          fileKey,
          result: file,
          columnCount: 0,
          rowCount: 0,
          fileType,
        });
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const parseYAMLFile = (file: Blob) =>
  new Promise<{ json: unknown; yaml: string }>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          const contents = reader.result as string;
          resolve({ json: parse(contents), yaml: contents });
        } catch (e) {
          reject(e);
        }
      };
      reader.readAsText(file);
    } catch (e) {
      reject(e);
    }
  });

export const parseYAMLDocument = (code: string) => {
  const document = parseDocument(code);
  const errors = document.errors.map(err => err.message);

  return errors;
};

export type ValidationResponse = {
  accepted: FileUpload[];
  rejected: { file: FileUpload; errors: DataError[] }[];
};

/**
 * File validation for uploading (Only CSV, JSON, and JSONL)
 */
export const validateFiles = (
  files: FileUpload[],
  extensions = [
    CSV_EXTENSION_REGEX,
    JSON_EXTENSION_REGEX,
    JSONL_EXTENSION_REGEX,
    PARQUET_EXTENSION_REGEX,
  ]
) =>
  files.reduce(
    (acc, file) => {
      const errors: DataError[] = [];
      if (!FILENAME_REGEX.test(file.name)) {
        errors.push(INVALID_FILENAME_ERROR);
      }

      if (!extensions.filter(regex => regex.test(file.name)).length) {
        errors.push(INVALID_FILE_TYPE_ERROR);
      }

      if (errors.length) {
        acc.rejected.push({ file, errors });
      } else {
        acc.accepted.push(file);
      }

      return acc;
    },
    { accepted: [], rejected: [] } as ValidationResponse
  );

/**
 * Parse data that is in either JSON or JSON-Lines format
 */
export const parseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error: unknown) {
    if (error instanceof SyntaxError) {
      return parseJSONL(data);
    }
    return error;
  }
};

/**
 * Parse JSON Lines data into a javascript array and return.
 */
export const parseJSONL = (data: string) => {
  const jsonl: Record<string, unknown>[] = [];
  const errors: { error: unknown; line: string }[] = [];
  for (const line of data.split(/\r?\n/g)) {
    if (line) {
      try {
        jsonl.push(JSON.parse(line));
      } catch (error: unknown) {
        // this COULD report the error to the calling context
        // but it's out of the scope of the task i'm on to do any refactoring
        // to handle that. for now, let's see if this ends up happening much IRL
        // for MY UI (in Navigator™️) it's more important to keep parsing and
        // have a dropped row than it is to report on syntax errors of a
        // user uploaded file. -@walston
        // update: instead of logging each individual instance, we should collect
        // them all and log them at once. -md
        errors.push({ error, line });
      }
    }
  }
  if (errors.length) {
    logger.error(new Error('JSONL ParseError'), { errors });
  }
  return jsonl;
};

/**
 * Filters keys out of an object whose value is null or optionally undefined.
 *
 */
export const nullFilter = <T extends object>(
  obj: T = {} as T,
  filterUndefined = true
) =>
  Object.entries(obj).reduce(
    (filteredResult, [key, value]) =>
      value !== null && (!filterUndefined || value !== undefined)
        ? { ...filteredResult, [key]: value }
        : filteredResult,
    {}
  );

/**
 * Turns a hash of projects into an array sorted by display_name.
 * I dunno where else to put this.
 */
export const getProjectsSorted = (
  projects: Record<string, Project>,
  field = 'display_name',
  asc = true
) =>
  (Object.values(projects || {}) || []).sort((a, b) => {
    const projectA = { ...a };
    const projectB = { ...b };
    // Needed to correctly sort 'Untitled Project' when display_name = undefined
    if (field === 'display_name') {
      projectA[field] = Formatters.Project.displayName(
        a.display_name
      ).toLowerCase();
      projectB[field] = Formatters.Project.displayName(
        b.display_name
      ).toLowerCase();
    }
    return asc
      ? projectA[field] > projectB[field]
        ? 1
        : -1
      : projectB[field] > projectA[field]
        ? 1
        : -1;
  });

/**
 * Turns a record of artifacts into an array sorted by display_name.
 * I dunno where else to put this.
 */
export const getArtifactsSorted = (
  artifacts: Record<string, ProjectArtifact>
) =>
  (Object.values(artifacts || {}) || []).sort((a, b) =>
    Formatters.Project.artifactName(a.artifactKey).toLocaleLowerCase() >
    Formatters.Project.artifactName(b.artifactKey).toLocaleLowerCase()
      ? 1
      : -1
  );

/**
 * Gets the chart value of a PPL score since the score is out of 6 and the chart is
 * divided into 5 sections
 * 0: 'Normal'(50% into 'Normal' section)
 * 1: 'Good' (30% into 'Good' section)
 * 2: 'Good' (70% into 'Good' section)
 * 3: 'Very Good' (30% into 'Very Good' section)
 * 4: 'Very Good' (70% into 'Very Good' section)
 * 5: 'Excellent' (30% into 'Excellent' section)
 * 6: 'Excellent' (70% into 'Excellent' section)
 */
export const getPplChartValue = (value: number) =>
  [1.8, 2.76, 3.24, 3.96, 4.44, 5.16, 5.64][value];

/**
 * Takes in a filename and returns its FILE_TYPE based on its extension.
 */
export const getFileType = (filename: string): FILE_TYPES => {
  if (CSV_EXTENSION_REGEX.test(filename)) {
    return FILE_TYPES.CSV;
  } else if (JSON_EXTENSION_REGEX.test(filename)) {
    return FILE_TYPES.JSON;
  } else if (JSONL_EXTENSION_REGEX.test(filename)) {
    return FILE_TYPES.JSONL;
  } else if (PARQUET_EXTENSION_REGEX.test(filename)) {
    return FILE_TYPES.PARQUET;
  }
  return FILE_TYPES.UNSUPPORTED;
};

/**
 * Creates & appends an <a> element with an href to the file
 * using window.URL.createObjectURL, clicks it to initiate
 * a download, then removes it.
 */
export const downloadFileFromLink = (
  file: File,
  name: string,
  type?: string
) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([file]));
  link.setAttribute('download', name);
  link.type = type || file?.type || 'text/csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Creates & appends an <a> element with an href to the file
 * using window.URL.createObjectURL, clicks it to open in
 * a new window, then removes it.
 */
export const openFileNewWindowFromLink = (
  file: File,
  name: string,
  type?: string
) => {
  const link = document.createElement('a');
  link.type = type || file?.type || 'text/csv';
  link.href = window.URL.createObjectURL(new Blob([file], { type: link.type }));
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Similar to downloadFileFromLink. Creates a link
 * element, clicks it and then removes.
 *
 * @param url - url to download from
 */
export const downloadFromLink = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Depending on the model status determine how to format the
 * training time for the user
 *
 * @param status - model/workflow status
 * @param completedTS - timestamp of when model/workflow was completed
 * @param activeTS - timestamp of when model/workflow became active (started).
 *
 * @returns string - formatted based on status
 */
export const getTrainingTimeString = (
  status: Status,
  completedTS?: DateStringISO,
  activeTS?: DateStringISO
) => {
  if (['cancelled', 'error'].includes(status)) {
    return '\u2014'; // return "—" (emdash).
  }
  if (['pending', 'active'].includes(status)) {
    return 'Training...';
  }
  if (completedTS && activeTS) {
    return shorthandDistance({ start: activeTS, end: completedTS });
  }
  return '';
};
