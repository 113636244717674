import React from 'react';
import { Typography } from '@mui/material';
import { ChatCodeBlock } from './components/ChatCodeBlock';
import { CodeBlock, NavigatorMessage } from './types';

/**
 * Generates a unique ID with a given prefix.
 * @param prefix - The prefix for the ID.
 * @returns A unique ID string.
 */
const generateUniqueId = (prefix: string): string =>
  `${prefix}_${Date.now().toString(16) + Math.random().toString(16).substring(2)}`;

export const generateMessageId = (): string => generateUniqueId('msg');
export const generateThreadId = (): string => generateUniqueId('thread');

export enum STAGE {
  READY = 'STAGE.READY',
  WAITING = 'STAGE.WAITING',
  CHUNKING = 'STAGE.CHUNKING',
}

/**
 * Extracts the first code block from a navigator message.
 * @param text - The text containing the code block.
 * @returns A CodeBlock object or null if no code block is found.
 */
export const extractCodeBlock = (text: string): CodeBlock | null => {
  const regex = /```(?:\n|([^`\n]+)\n)([\s\S]*?)(?:```|$)/;
  const match = regex.exec(text);

  if (!match) {
    return null;
  }

  const [, language = '', code = ''] = match;
  return { language, code: code.trim() };
};

export const initialMessages: NavigatorMessage[] = [
  {
    id: generateMessageId(),
    role: 'assistant',
    content:
      'What programming language would you like to generate text-to-code for? Supported languages include Python and SQL',
  },
];

export const MarkdownToMuiMapping = {
  // headings
  h1: ({ node, ...props }) => <Typography variant="h1" {...props} />,
  h2: ({ node, ...props }) => <Typography variant="h2" {...props} />,
  h3: ({ node, ...props }) => <Typography variant="h3" {...props} />,
  h4: ({ node, ...props }) => <Typography variant="h4" {...props} />,
  h5: ({ node, ...props }) => <Typography variant="h5" {...props} />,
  h6: ({ node, ...props }) => <Typography variant="h6" {...props} />,
  // lists
  li: ({ node, ...props }) => (
    <li>
      <Typography variant="body2" {...props} />
    </li>
  ),
  // misc text
  strong: ({ node, ...props }) => (
    <Typography variant="body2Strong" {...props} />
  ),
  p: ({ node, ...props }) => (
    <Typography variant="body2" component="p" {...props} />
  ),
  code: ({ node, children, ...props }) => {
    let language = 'text';
    if (props.className) {
      const classList = props.className.split(' ');
      classList.forEach(className => {
        if (className.startsWith('language-')) {
          language = className.replace('language-', '');
        }
      });
    }
    return <ChatCodeBlock language={language} code={children} />;
  },
};
