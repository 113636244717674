import Cookies from 'js-cookie';
import { USER_TOKEN } from 'constants/cookieKeys';

export type JWTToken = {
  exp: number;
  email: string;
  username: string;
  _id: string;
  guid: string;
  new_signup: boolean;
};

const getBearerToken = () => {
  const urlToken = new URLSearchParams(window.location.search).get('token');
  if (urlToken) {
    return urlToken;
  }

  const cookieToken = Cookies.get(USER_TOKEN);
  if (cookieToken) {
    return JSON.parse(cookieToken) as string;
  }
};

export const setToken = (token: string) => {
  Cookies.set(USER_TOKEN, JSON.stringify(token), { secure: true });
};

export const clearToken = () => {
  Cookies.remove(USER_TOKEN);
};

/**
 * Use as http header:
 * ```javascript
 * req.headers.set('Authorization', Authorization())
 * ```
 */
export const getAuthorization = () => {
  if ('Cypress' in window) {
    return localStorage.getItem('cypressToken') ?? 'missing-token';
  }

  const token = getBearerToken();
  if (token) {
    if (token.startsWith('grtu')) {
      return token;
    }
    return `Bearer ${token}`;
  }
};
