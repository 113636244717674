// Gretel dark theme for Codeblocks
import { useTheme } from '@mui/material/styles';
import { PrismTheme } from 'prism-react-renderer';

export const useThemeForDarkPrism = (): PrismTheme => {
  const theme = useTheme();
  return {
    // @ts-expect-error: Prism and mui have incompatible CSS typing
    plain: {
      color: theme.palette.primary[50],
      backgroundColor: theme.palette.primary[950],
      ...theme.typography.code,
    },
    styles: [
      {
        types: ['important', 'bold'],
        style: {
          // @ts-expect-error: Prism and mui have incompatible CSS typing
          fontWeight: `${theme.typography.fontWeightBold}`,
        },
      },

      {
        types: ['italic'],
        style: {
          fontStyle: 'italic',
        },
      },
    ],
  };
};

export const useThemeForLightPrism = (): PrismTheme => {
  const theme = useTheme();
  return {
    // @ts-expect-error: Prism and mui have incompatible CSS typing
    plain: {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.background.secondary,
      ...theme.typography.code,
    },
    styles: [
      {
        types: ['comment', 'prolog', 'doctype', 'cdata'],
        style: {
          color: theme.palette.grey[600],
        },
      },
      {
        types: [
          'property',
          'atrule',
          'attr-value',
          'attr-name',
          'char',
          'builtin',
          'inserted',
          'punctuation',
          'operator',
        ],
        style: {
          color: theme.palette.grey[950],
        },
      },
      {
        types: ['selector', 'string', 'tag', 'constant', 'symbol'],
        style: {
          color: theme.palette.primary[500],
        },
      },
      {
        types: ['number', 'boolean', 'entity', 'url', 'variable'],
        style: {
          color: theme.palette.success.main,
        },
      },
      {
        types: ['keyword', 'regex', 'important', 'deleted'],
        style: {
          color: theme.palette.error.main,
        },
      },
      {
        types: ['function'],
        style: {
          color: 'rgb(170, 93, 0)',
        },
      },
      {
        types: ['important', 'bold'],
        style: {
          // @ts-expect-error: Prism and mui have incompatible CSS typing
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      {
        types: ['italic'],
        style: {
          fontStyle: 'italic',
        },
      },
    ],
  };
};
