import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { system_prompt } from './constants';
import { NavigatorMessage } from './types';
import { extractCodeBlock, STAGE } from './utils';

export type SelectedRelationalType =
  | 'entireDatabase'
  | 'tableNames'
  | 'sqlQuery';

export type ChatState = {
  threadName: string;
  messages: NavigatorMessage[];
  code: string;
  systemPrompt: string;
  model: string;
  usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
  stage: STAGE;
};

export const initialState: ChatState = {
  threadName: 'New Text-to-Code Blueprint',
  messages: [],
  code: '# Your config will appear here',
  systemPrompt: system_prompt,
  model: 'gpt-4o',
  usage: {
    completion_tokens: 0,
    prompt_tokens: 0,
    total_tokens: 0,
  },
  stage: STAGE.READY,
};

export const chatDuck = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMetadata: (
      state: ChatState,
      action: PayloadAction<Partial<ChatState>>
    ) => {
      const metadata = action.payload;

      return {
        ...state,
        ...metadata,
      };
    },
    setThreadName: (state: ChatState, action: PayloadAction<string>) => {
      state.threadName = action.payload;
    },
    updateMessages: (
      state: ChatState,
      action: PayloadAction<NavigatorMessage[]>
    ) => {
      state.messages = [...action.payload];
    },
    addUserMessage: (
      state: ChatState,
      action: PayloadAction<NavigatorMessage>
    ) => {
      state.messages = [...state.messages, action.payload];
    },
    addOrUpdateAssistantMessage: (
      state: ChatState,
      action: PayloadAction<NavigatorMessage>
    ) => {
      if (state.messages[state.messages.length - 1]?.role === 'assistant') {
        state.messages = [
          ...state.messages.slice(0, state.messages.length - 1),
          action.payload,
        ];
      } else {
        state.messages.push(action.payload);
      }

      const content =
        typeof action.payload.content === 'string'
          ? action.payload.content
          : '';
      const extractedCode = extractCodeBlock(content);
      if (extractedCode?.code) {
        state.code = extractedCode.code;
      }
    },
    setCode: (state: ChatState, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setStage: (state: ChatState, action: PayloadAction<STAGE>) => {
      state.stage = action.payload;
    },
    setSystemPrompt: (state: ChatState, action: PayloadAction<string>) => {
      state.systemPrompt = action.payload;
    },
    setModel: (state: ChatState, action: PayloadAction<string>) => {
      state.model = action.payload;
    },
    setUsage: (state: ChatState, action: PayloadAction<ChatState['usage']>) => {
      state.usage = action.payload;
    },

    reset: () => initialState,
  },
});

export const {
  setChatMetadata,
  setThreadName,
  updateMessages,
  setCode,
  setStage,
  setSystemPrompt,
  setModel,
  setUsage,
  reset,
} = chatDuck.actions;
